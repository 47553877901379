import { ReactNode, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import ScrollToTop from './ScrollToTop';
import Contact from './components/contact/Contact';
import Footer from './components/navigation/Footer';
import Navbar from './components/navigation/Navbar';
import Admin from './containers/Admin/Admin';
import Gallery from './containers/Gallery/Gallery';
import Landingpage from './containers/Landingpage/Landingpage';
import Login from './containers/Login/Login';
import Datenschutz from './containers/comInfo/Datenschutz';
import Impressum from './containers/comInfo/Impressum';
import Unternehmen from './containers/comInfo/Unternehmen';
import { isAuthenticated } from './utils/auth';
import ComparisonGallery from './containers/ComparisonGallery/ComparisonGallery';
import NotFoundPage from './containers/NotFound/NotFoundPage';

interface ProtectedRouteProps {
  children: ReactNode;
}

function isTokenExpired(token: string): boolean {
  if (!token) return true;

  const { exp } = JSON.parse(atob(token.split('.')[1]));
  const currentTime = Date.now() / 1000;

  return exp < currentTime;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    if (!isAuthenticated() || !token || isTokenExpired(token)) {
      localStorage.removeItem('authToken');
      navigate('/login');
    }
  }, [navigate, token]);

  return <>{children}</>;
};


function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/ueber-uns" element={<Unternehmen />} />
          <Route path="/galerie" element={<Gallery />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/vorher-nachher" element={<ComparisonGallery />} />
          <Route path="/*" element={<NotFoundPage />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
