import ComparisonSlider from "../compSlider/CompSlider";

export default function HeroSection() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-24 rounded-3xl">
                <div className="relative isolate max-lg:pb-12 h-full overflow-hidden rounded-3xl bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                    <svg
                        viewBox="0 0 1024 1024"
                        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                        aria-hidden="true"
                    >
                        <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                        <defs>
                            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                <stop stopColor="#7775D6" />
                                <stop offset={1} stopColor="#E935C1" />
                            </radialGradient>
                        </defs>
                    </svg>
                    <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            Vorher-Nachher-Projekte
                            <br />
                            als Inspiration.
                        </h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            Lassen Sie sich von unseren Installationen bei Kunden inspirieren.
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                            <a
                                href="/vorher-nachher"
                                className="rounded-md flex items-center bg-white gap-2 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >
                                <p>Zum Vorher-Nachher-Vergleich&nbsp;</p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="mx-auto max-w-xl text-center lg:mx-0 lg:flex-auto my-auto lg:text-left">
                        <ComparisonSlider></ComparisonSlider>
                        <span className="text-white text-sm max-lg:hidden">* Beispiel für eine von uns vorgenommene Installation. Weitere Beispiele können Sie in unserer Galerie begutachten.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}