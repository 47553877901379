import hoermann from "../../assets/images/hrmann-logo-neu.webp"
import "./HoermannBadge.css"

function HoermannBadge() {
    return (
        <div className='flex items-center gap-12 mx-auto h-badge'>
            <img src={hoermann} alt="" />
            <p className="font-medium text-gray-600 text-left">Die Hörmann KG mit Sitz im ostwestfälischen Steinhagen ist ein deutscher Hersteller von Toren, Türen, Zargen und Antrieben.
                Wir sind seit 1995 qualifizierter Fachhändler der Hörmann KG
            </p>
        </div>
    )
}

export default HoermannBadge;