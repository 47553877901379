import React, { useEffect, useState } from 'react';
import F80A from "../../assets/images/comparison/F 80 Tor - Vorher (1).JPG";
import F80P from "../../assets/images/comparison/F 80 Tor - Vorher (2).JPG";
import FP from "../../assets/images/comparison/Fassade-Vorher (2.2).JPG";
import FA from "../../assets/images/comparison/Fassade-Nachher (2.2).JPG";
import BP from "../../assets/images/comparison/Vorher- Balkon(2.2).JPG";
import BA from "../../assets/images/comparison/Nacher- Balkon(2.2).jpg";
import GP from "../../assets/images/comparison/Vorher-Geländer (1.1).jpg";
import GA from "../../assets/images/comparison/Nacher-Geländer (1.1).JPG";
import SA from "../../assets/images/comparison/Tor-Sektionaltor-Vorher(2.2) (2).JPG";
import SP from "../../assets/images/comparison/Tor-Sektionaltor-Vorher(2.2) (1).JPG";
import TA from "../../assets/images/comparison/Vorher-Neubau Treppen- (1.1) (2).JPG";
import TP from "../../assets/images/comparison/Vorher-Neubau Treppen- (1.1) (1).JPG";
import HA from "../../assets/images/comparison/Vorher Holztor 2.jpg";
import HP from "../../assets/images/comparison/Nachher Rolltor 2.jpg";

const HardCompSlider = () => {
    const [sliderPosition, setSliderPosition] = useState<{ [key: number]: number }>({});
    const [activeSlider, setActiveSlider] = useState<number | null>(null);

    const updateSliderPosition = (clientX: number, rect: { left: number; width: number; }, id: any) => {
        const x = clientX - rect.left;
        const newSliderPosition = Math.max(0, Math.min(100, (x / rect.width) * 100));
        setSliderPosition({ ...sliderPosition, [id]: newSliderPosition });
    };

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number | null) => {
        if (activeSlider !== id) return;
        const rect = event.currentTarget.getBoundingClientRect();
        updateSliderPosition(event.clientX, rect, id);
    };

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>, id: number | null) => {
        if (activeSlider !== id) return;
        const rect = event.currentTarget.getBoundingClientRect();
        const touch = event.touches[0];
        updateSliderPosition(touch.clientX, rect, id);
    };

    const handleMouseDown = (id: React.SetStateAction<number | null>) => {
        setActiveSlider(id);
    };

    const handleMouseUp = () => {
        setActiveSlider(null);
    };

    const lgData = [
        {
            id: 1,
            prev: F80A,
            after: F80P,
            label: "Aluminium Garagentor"
        },
        {
            id: 2,
            prev: FP,
            after: FA,
            label: "Hallenverkleidungen aus Aluminium"
        },
        {
            id: 3,
            prev: HA,
            after: HP,
            label: "Holztor / Rolltor"
        },
        {
            id: 4,
            prev: SP,
            after: SA,
            label: "Sectionaltor"
        },
        {
            id: 6,
            prev: TP,
            after: TA,
            label: "Treppen am Hauseingang"
        },
        {
            id: 7,
            prev: GP,
            after: GA,
            label: "Terassengeländer"
        },
        {
            id: 8,
            prev: BP,
            after: BA,
            label: "Balkone an einem Wohnhaus"
        },
    ]

    return (
        <div className='max-md:w-full w-3/4 mx-auto relative grid gap-6 grid-cols-1' onMouseUp={handleMouseUp} onTouchEnd={handleMouseUp}>
            {lgData.map(obj => (
                <div>
                    <div
                        className='relative w-full select-none max-w-[1280px] aspect-[70/45] m-auto rounded-2xl overflow-hidden'
                        onMouseMove={(event) => handleMouseMove(event, obj.id)}
                        onTouchMove={(event) => handleTouchMove(event, obj.id)}
                        onMouseDown={() => handleMouseDown(obj.id)}
                        onTouchStart={() => handleMouseDown(obj.id)}
                    >
                        <img loading='lazy' src={obj.after} alt="" className='w-full h-full' />
                        <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-gray-500 absolute bottom-6 right-6">Nachher</span>
                        <div
                            className='absolute select-none top-0 left-0 right-0 w-full max-w-[1280px] aspect-[70/45] m-auto overflow-hidden'
                            style={{ clipPath: `inset(0 ${100 - (sliderPosition[obj.id] || 50)}% 0 0)` }}
                        >
                            <img loading='lazy' src={obj.prev} alt="" className='w-full h-full' />
                            <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-gray-500 absolute bottom-6 left-6">Vorher</span>

                        </div>
                        <div
                            className='absolute top-0 bottom-0 w-1 bg-white cursor-ew-resize'
                            style={{ left: `calc(${(sliderPosition[obj.id] || 50)}% - 1px)` }}
                        >
                            <div className='bg-white absolute rounded-full h-5 w-5 -left-2 top-[calc(50%-5px)]' />
                        </div>
                    </div>

                    <p className="text-left rtl:text-right text-gray-500 dark:text-gray-400">{obj.label}</p>
                </div>
            ))}
        </div>
    );
};

export default HardCompSlider;


