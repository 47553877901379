import { useEffect } from "react";
import "./Unternehmen.css"

function Unternehmen() {
    useEffect(() => {
        document.title = 'Metallbau-Fleischmann | Über uns';
    }, []);
    return (
        <div className='u-container'>
            <div className="relative isolate pt-32 max-w-[1280px] mx-[48px] text-left">
                <h2 className="mb-2 text-3xl font-semibold text-gray-900 text-left pb-12">Wir über uns</h2>
                <ul className="w-full space-y-1 text-left text-gray-500 list-none list-inside ">
                    <li>
                        <p>
                            Wir sind ein mittelständiges Unternehmen und seit 50 Jahren für Sie da. Unser Leistungsspektrum reicht von „Stuhl schweißen/ reparieren“ über jegliche Metallarbeiten betreffend Haus/ Hof/ Garten bis zu Lieferteilen für die Industrie sowie „kleinerer Stahlbau“ und Errichtung von Hallen.
                        </p>
                        <br />
                        <p>
                            Unser Hauptaugenmerk liegt dabei bei Tür-/ Tor- und Zaunanlagen und allem was sich „bewegt“.
                            Als HÖRMANN – Fachhändler haben wir einen der größten Zulieferer von Tor- und Bauelementen an unserer Seite.
                            Unsere Referenzen reichen von der Frauenkirche Dresden, Wernesgrüner Brauerei, Schloss Berlin (noch im Bau), Soletherme Bad Elster sowie Dreh- und Montagevorrichtungen für „MAHLE“ – Werke in China und „Windspiele“ in Rostock. Weitere Bauvorhaben quer durch Deutschland unterstreichen unsere Vielseitigkeit.
                        </p>
                        <br />
                        <p>
                        Wir haben einen umfangreichen und gepflegten Fuhr- und Maschinenpark. Dieser umfasst einen eigenen LKW MAN mit 3 to Ladekran, diverse Transporter- Kastenwagen wie Mercedes – Benz Sprinter und Vito sowie Transporter VW-T 5 komplett ausgestattet mit Werkzeug, eigene Fahr- und Rollgerüste, selbstfahrende Hubarbeitsbühne bis 8,50 Meter Arbeitshöhe, weiterhin sämtliche Stahl- und Blechbearbeitungsmaschinen, wie Drehmaschine, Rohr- und Profil-Biegemaschinen rund, eckig, oval/unsymmetrisch, diverse Lochstanzen und Profilstahlscheren der Marke Peddinghaus und Mubea, Blechscheren zum Schneiden von Stahlblech max. 3000 x 12 mm und 2500 x 15 mm dick, Weinbrenner CNC – Abkantpressen mit 3000 mm Kantlänge und max. 125 to Druckkraft, Richt- und Biegemaschine bis 150 to Druckkraft, CNC – Stanz- Nippelmaschine zur Blechbearbeitung bis 1250 x 2500 mm.
                            Verschiedene Schweißmaschinen der Firma ESS/ Closs/ Kemppi für fast alle Schweißverfahren von Stahl/ Edelstahl und Aluminium. Auch Hochfrequenz -Pulsschweißen.
                            Es gibt nicht viel was wir noch nicht gemacht/ angefertigt/ hergestellt haben, von der klappbaren Schornsteinabdeckung bis zur Klärgrubenreparatur/ Abdichtung unter Tage.
                        </p>
                        <br />
                        <h1 className="text-2xl">
                            Ihr Bau – unser <span className="text-indigo-400 font-bold">Schlau</span> von Keller bis zum Dach, unser <span className="text-indigo-400 font-bold">Fach</span> ! 
                        </h1>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Unternehmen;