import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div className='container h-screen flex items-center justify-center w-full mx-auto'>
            <div className='acryl-container'>
                <h1 className='text-9xl'>404</h1>
                <p className='mb-8'>Die angeforderte Seite existiert nicht.</p>
                <Link className='hover:underline' to={'/'}>Kehren Sie zur Startseite zurück&nbsp;&#10138;</Link>
            </div>
        </div>
    );
};

export default NotFoundPage;
