import React from 'react';
import Carport from "../../assets/images/landingGallery/Carports-Überdachungen.JPG"
import Balkon from "../../assets/images/landingGallery/Balkone-Balkongeländer.JPG"
import DrehAlu from "../../assets/images/landingGallery/Drehtore mit Aluminiumverkleidung.JPG"
import DrehHolz from "../../assets/images/landingGallery/Drehtore mit Holzverkleidung.JPG"
import DrehStahl from "../../assets/images/landingGallery/Drehtore mit Stahl- Edelstahlverkleidung.JPG"
import Fenster from "../../assets/images/landingGallery/Fenstergitter.JPG"
import Gelaender from "../../assets/images/landingGallery/Geländer.jpg"
import Halle from "../../assets/images/landingGallery/Hallenverkleidungen.JPG"
import Rolltor from "../../assets/images/landingGallery/Rolltore.JPG"
import SchiebeAlu from "../../assets/images/landingGallery/Schiebetore mit Aluminiumverkleidung.JPG"
import SchiebeHolz from "../../assets/images/landingGallery/Schiebetore mit Holzverkleidung.JPG"
import SChiebeStahl from "../../assets/images/landingGallery/Schiebetore mit Stahl-Edelstahlverkleidung.JPG"
import Schwing from "../../assets/images/landingGallery/Schwingtore.JPG"
import Section from "../../assets/images/landingGallery/Sectionaltore.JPG"
import Sonstiges from "../../assets/images/landingGallery/Sonstiges.JPG"
import Terasse from "../../assets/images/landingGallery/Terassen-Markisen.jpg"
import Treppe from "../../assets/images/landingGallery/Treppen.jpg"
import Zaun from "../../assets/images/landingGallery/Zäune.jpg"
import "./LandingCategoryGallery.css"
import { Link } from 'react-router-dom';

interface LandingCategoryGalleryProps {
    // Definiere hier Props, falls benötigt
}

const LandingCategoryGallery: React.FC<LandingCategoryGalleryProps> = (props) => {

    const lgData = [
        {
            id: 1,
            thumbnail: DrehHolz,
            label: "Drehtore mit Holzverkleidung"
        },
        {
            id: 2,
            thumbnail: DrehStahl,
            label: "Drehtore mit Stahl-, Edelstahlverkleidung"
        },
        {
            id: 3,
            thumbnail: DrehAlu,
            label: "Drehtore mit Aluminiumverkleidung"
        },
        {
            id: 4,
            thumbnail: SchiebeHolz,
            label: "Schiebetore mit Holzverkleidung"
        },
        {
            id: 5,
            thumbnail: SChiebeStahl,
            label: "Schiebetore mit Stahl-, Edelstahlverkleidung"
        },
        {
            id: 6,
            thumbnail: SchiebeAlu,
            label: "Schiebetore mit Aluminiumverkleidung"
        },
        {
            id: 7,
            thumbnail: Section,
            label: "Sectionaltore"
        },
        {
            id: 8,
            thumbnail: Rolltor,
            label: "Rolltore"
        },
        {
            id: 9,
            thumbnail: Schwing,
            label: "Schwingtore"
        },
        {
            id: 10,
            thumbnail: Balkon,
            label: "Balkone und Balkongeländer"
        },
        {
            id: 11,
            thumbnail: Gelaender,
            label: "Geländer"
        },
        {
            id: 12,
            thumbnail: Treppe,
            label: "Treppen"
        },
        {
            id: 13,
            thumbnail: Carport,
            label: "Carports und Überdachungen"
        },
        {
            id: 14,
            thumbnail: Terasse,
            label: "Terrassen und Markisen"
        },
        {
            id: 15,
            thumbnail: Halle,
            label: "Hallenverkleidungen"
        },
        {
            id: 16,
            thumbnail: Zaun,
            label: "Zäune"
        },
        {
            id: 17,
            thumbnail: Fenster,
            label: "Fenstergitter"
        },
        {
            id: 18,
            thumbnail: Sonstiges,
            label: "Sonstiges"
        },
    ]

    return (
        <div className="relative h-full isolate pt-[100px] max-w-[1280px] max-[1328px]:mx-[48px] max-sm:mx-[14px] mb-24 mx-auto">

            <h1 className="text-5xl font-extrabold text-left pb-12">Galerie&nbsp;<small className="ms-2 font-semibold text-gray-500 max-sm:hidden">Entdecken Sie unsere Arbeiten der letzten Jahre.</small></h1>

            <div className="relative grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 gap-y-12">
                {lgData.map((item) => (
                    <div key={item.id} className='h-auto relative lg-contentHolder'>
                        <img className="h-full w-full object-cover rounded-lg" src={item.thumbnail} alt="" />
                        <p className="text-left rtl:text-right text-gray-500 dark:text-gray-400">{item.label}</p>
                        <span className='absolute w-full h-full top-0 left-0  flex justify-center items-center'>
                            <Link to={`/galerie?category=${item.label}`} className='bg-blue-600 rounded-md w-2/3 px-3 py-2 z-50 text-white'>
                                Jetzt {item.label} entdecken
                            </Link>
                        </span>
                    </div>
                ))}
            </div>

        </div >
    );
};

export default LandingCategoryGallery;


