import React, { useEffect, useState } from "react";
import "./Contact.css";


const Contact = () => {
    const [formValues, setFormValues] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    const [formAttempted, setFormAttempted] = useState(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const [agreement, setAgreement] = useState(false);

    const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setAgreement(event.target.checked);
    }

    const getInputValidationClass = (name: keyof typeof formValues, attempted: boolean) => {
        if (!attempted) {
            return "";
        }

        if (formValues[name] === "") {
            return "invalid";
        }

        return "";
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setFormAttempted(true);

        if (
            formValues.name === "" ||
            formValues.email === "" ||
            formValues.message === ""
        ) {
            return;
        }

        const formData = new FormData(event.currentTarget);
        const name = formData.get("name") as string;
        const email = formData.get("email") as string;
        const phone = formData.get("phone") as string;
        const message = formData.get("message") as string;

        const response = await fetch("/api/send-email", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                email,
                phone,
                message,
            }),
        });

        if (response.ok) {
            alert("E-Mail erfolgreich gesendet.");
        } else {
            const errorData = await response.json();
            if (errorData.message === "Die E-Mail-Domain existiert nicht.") {
                alert("Die E-Mail-Domain existiert nicht.");
            } else if (errorData.message === "Ungültige E-Mail-Adresse.") {
                alert("Ungültige E-Mail-Adresse.");
            } else {
                alert("Fehler beim Senden der E-Mail.");
            }
        }
    };

    useEffect(() => {
        document.title = 'Metallbau-Fleischmann | Kontakt';
    }, []);

    return (
        <div className='imp-container'>
            <div className="relative isolate pt-32 max-w-[1280px] w-full mx-[48px]">
                <div className="contactPage">

                    <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl ">Wir haben Ihr Interesse geweckt?</h1>
                    <p className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 ">Stellen Sie uns gerne Ihre Fragen für Bauvorhaben oder Inspirationen aus der Galerie.</p>

                    <section className="ContactForm">
                        <form onSubmit={handleSubmit}>
                            <div className="inputGroup">
                                <input
                                    type="text"
                                    name="name"
                                    value={formValues.name}
                                    onChange={handleInputChange}
                                    placeholder="Name"
                                    required
                                    autoComplete="name"
                                    className={getInputValidationClass("name", formAttempted)}
                                />
                            </div>
                            <div className="inputGroup">
                                <input
                                    type="email"
                                    name="email"
                                    value={formValues.email}
                                    onChange={handleInputChange}
                                    placeholder="E-Mail"
                                    required
                                    autoComplete="email"
                                    className={getInputValidationClass("email", formAttempted)}
                                />
                            </div>
                            <div className="inputGroup">
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formValues.phone}
                                    onChange={handleInputChange}
                                    placeholder="Telefon (optional)"
                                    autoComplete="tel"
                                />
                            </div>
                            <div className="inputGroup">
                                <textarea
                                    name="message"
                                    value={formValues.message}
                                    onChange={handleInputChange}
                                    placeholder="Ihr Anliegen"
                                    required
                                    className={getInputValidationClass("message", formAttempted)}
                                />
                            </div>
                            <div className="agb-consent-holder">
                                <input type="checkbox" name="agreement" onChange={handleChange} className="agb-consent-checkbox" />
                                <p>Ich habe die Datenschutzerklärung zur Kenntnis genommen.
                                    Ich stimme zu, dass die von mir übermittelten Daten zur Kontaktaufnahme und für Rückfragen dauerhaft gespeichert werden. Diese Zustimmung kann ich jederzeit persönlich widerrufen.
                                </p>
                            </div>
                            <button className="bg-blue-600 py-2 px-6 rounded-xl text-white border" disabled={!agreement} type="submit">Anfrage senden</button>
                        </form>
                    </section>
                </div>
            </div>
        </div>

    );
};

export default Contact;

