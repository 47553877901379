import React from 'react';
import HardCompSlider from '../../components/compSlider/HardCompSlider';

interface ComparisonGalleryProps {
    // Definiere hier Props, falls benötigt
}

const ComparisonGallery: React.FC<ComparisonGalleryProps> = (props) => {

    return (
        <div className="relative h-full isolate pt-[100px] max-w-[1280px] max-[1328px]:mx-[48px] max-sm:mx-[14px] mb-24 mx-auto">

            <h1 className="text-5xl font-extrabold text-left pb-12">Vorher-Nachher-Vergleich&nbsp;<small className="ms-2 font-semibold text-gray-500 max-sm:hidden">Einige unserer besten Arbeiten. Kundenzufriedenheit und Qualität stehen für uns an oberster Stelle</small></h1>

            <div className="relative">
                <HardCompSlider></HardCompSlider>
            </div>

        </div >
    );
};

export default ComparisonGallery;


