import { Link } from "react-router-dom";
import "./LandingHero.css"
import HoermannBadge from "../hoermannBadge/HoermannBadge";

function LandingHero() {
    return (
        <div className='flex items-center gap-12 mx-auto'>
            <section className="bg-transparent ">
                <div className="flex-col items-start justify-start max-w-screen-xl px-4 py-8 mx-auto">
                    <div className="flex-col items-start justify-start lg:col-span-7">
                        <h1 className="max-w-2xl mb-4 text-4xl font-extrabold md:text-5xl xl:text-6xl text-left">Metallbau Fleischmann</h1>
                        <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl text-left">Wir sind ein mittelständiges Unternehmen und seit 50 Jahren für Sie da.</p>
                        <HoermannBadge></HoermannBadge>
                        <div className="lg:mt-0 pb-12 h-full pt-4 flex-row items-start justify-start">
                            <div>
                                <h1 className="font-medium text-2xl text-left">Metall ist unsere Sache</h1>
                                <div className="flex items-start justify-start">
                                    <div className="flex flex-col">
                                        <span className="pr-4">&#9989;</span>
                                        <span className="pr-4">&#9989;</span>
                                        <span className="pr-4">&#9989;</span>
                                    </div>
                                    <div className="flex-col">
                                        <p className="w-full text-left">Blechbearbeitung</p>
                                        <p className="w-full text-left">CNC Abkanttechnik</p>
                                        <p className="w-full text-left">Stahl-, Aluminium-, Edelstahlschweißarbeiten</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex items-center justify-start action-holder">
                            <Link to={'/kontakt'} className="max-md:w-full inline-flex items-center justify-center px-8 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 ">
                                Jetzt Kontakt aufnehmen
                            </Link>
                            <Link to={'/galerie'} className="max-md:w-full inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 ">
                                Inspiration aus der Galerie holen
                                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default LandingHero;