import { Link } from "react-router-dom";
import logo from "../../assets/images/fleischmannlogo.webp"
import "./Footer.css"

function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <div className="pt-auto bottom-0 left-0 w-full">
            <footer className="bg-gray-200 shadow ">
                <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div className="sm:flex sm:items-center sm:justify-between">
                        <Link to='/' className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                            <img src={logo} className="h-8" alt="Flowbite Logo" />
                            <span className="logo-span self-center text-2xl font-semibold whitespace-nowrap text-gray-900">Metallbau Fleischmann</span>
                        </Link>
                        <ul className="flex flex-wrap items-center mb-6 text-sm font-medium gap-6 text-gray-900 sm:mb-0 ">
                            <li>
                                <Link to={"/ueber-uns"}>Wir über Uns</Link>
                            </li>
                            <li>
                                <Link to={"/impressum"}>Impressum</Link>
                            </li>
                            <li>
                                <Link to={"/datenschutz"}>Datenschutz</Link>
                            </li>
                        </ul>
                    </div>
                    <hr className="my-6 border-gray-900 sm:mx-auto lg:my-8" />
                    <span className="block text-sm text-gray-700 sm:text-center ">&copy;{currentYear},&nbsp;Günter Fleischmann GmbH.</span>
                </div>
            </footer>
        </div>
    )
}

export default Footer;