import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { isAuthenticated } from '../../utils/auth';
import './Admin.css';

interface ImageData {
    id: number;
    title: string;
    data: string;
    category: string;
}

function Admin() {
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [files, setFiles] = useState<File[]>([]);
    const [images, setImages] = useState<ImageData[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [imageToDelete, setImageToDelete] = useState<number | null>(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('authToken');
    const [addCategory, setAddCategory] = useState(false);
    const abortController = new AbortController();

    const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const newFiles = Array.from(e.dataTransfer.files) as File[];
        setFiles(prevFiles => [...prevFiles, ...newFiles]);
    };

    const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const newFiles = Array.from(e.target.files) as File[];
            setFiles(prevFiles => [...prevFiles, ...newFiles]);
        }
    };

    const removeFile = (index: number, e: { preventDefault: () => void; stopPropagation: () => void; } | undefined) => {
        e?.preventDefault();
        e?.stopPropagation();
        setFiles(files.filter((_, fileIndex) => fileIndex !== index));
    };

    const clearFiles = (e: { preventDefault: () => void; stopPropagation: () => void; }) => {
        e.preventDefault();
        e.stopPropagation();
        setFiles([]);
    };

    const loadImagesForCategory = async (category: string) => {
        try {
            const response = await fetch(`/api/image/images/category/${category}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                signal: abortController.signal 
            });
            const data = await response.json() as ImageData[];
            setImages(prevImages => {
                const newImages = data.filter(newImage => !prevImages.some(img => img.id === newImage.id));
                return [...prevImages, ...newImages];
            });
        } catch (error) {
            console.error('Fehler beim Abrufen der Bilder:', error);
        }
    };

    const loadAllImages = async () => {
        for (const category of categories) {
            await loadImagesForCategory(category);
        }
    };

    useEffect(() => {
        if (isAuthenticated()) {
            fetch('/api/image/categories', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => response.json())
                .then((categoriesData: string[]) => {
                    setCategories(categoriesData.sort());
                })
                .catch(error => console.error('Fehler beim Abrufen der Kategorien:', error));
        } else {
            navigate('/login');
        }
        return () => {
            abortController.abort();
        };
    }, [navigate, token]);

    useEffect(() => {
        if (isAuthenticated() && categories.length > 0) {
            loadAllImages();
        }
        return () => {
            abortController.abort();
        };
    }, [categories]);

    const handleCategorySelection = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedCategory(e.target.value);
    };

    const handleNewCategoryWithAlreadySelected = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedCategory('');
        setCategory(e.target.value)
    }

    const handleUpload = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const uploadCategory = selectedCategory || category;

        for (const file of files) {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('title', title);
            formData.append('category', uploadCategory);

            try {
                const response = await fetch('/api/image/upload', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData,
                });

                if (response.status === 401) {
                    localStorage.removeItem('authToken');
                    navigate('/login');
                    return;
                }

                if (!response.ok) {
                    throw new Error('Serverfehler beim Bildupload');
                }

                const result = await response.json();
                console.log(result);
            } catch (error) {
                console.error('Fehler beim Hochladen des Bildes:', error);
            }
        }
        window.location.reload();
    };

    const handleDeleteClick = (imageId: number) => {
        setImageToDelete(imageId);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        if (imageToDelete !== null) {
            fetch(`/api/image/${imageToDelete}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response.status === 401) {
                        localStorage.removeItem('authToken');
                        navigate('/login');
                        return;
                    }

                    if (!response.ok) {
                        throw new Error('Fehler beim Löschen des Bildes');
                    }

                    setImages(images.filter(image => image.id !== imageToDelete));
                })
                .catch(error => {
                    console.error('Fehler:', error);
                });
        }
        setShowModal(false);
        setImageToDelete(null);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
        setImageToDelete(null);
    };

    const handleAddNewcategoryInputField = () => {
        setAddCategory(true);
    }

    const handleCloseNewcategoryInputField = () => {
        setAddCategory(false);
    }

    return (
        <div className='admin-container max-w-[1280px] mx-auto'>
            <div className="container py-6">
                <h2 className="text-4xl font-extrabold ">Datei-Upload für Bildergalerie</h2>
                <p className="my-4 text-lg text-gray-500">Hier können Sie neue Bilder für die Galerie hochladen, beachten Sie bitte die Pflichtfelder.</p>
            </div>
            <form onSubmit={handleUpload} className='text-left'>
                <div className="container py-6">
                    <label htmlFor="helper-text" className="block mb-2 text-sm font-medium text-gray-900 ">Bildtitel</label>
                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Titel" id="helper-text" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  " />
                </div>
                <label htmlFor="categorySelect" className="block mb-2 text-sm font-medium text-gray-900 text-left w-full">Bitte Wählen Sie eine Kategorie *</label>
                {addCategory === true ? <select disabled value={selectedCategory} id='categorySelect' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleCategorySelection} placeholder='Kategorie auswählen'>
                    <option value="" disabled defaultChecked hidden>Bitte Kategorie wählen...</option>
                </select> : <select value={selectedCategory} id='categorySelect' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleCategorySelection} placeholder='Kategorie auswählen' required>
                    <option value="" disabled defaultChecked hidden>Bitte Kategorie wählen...</option>
                    {categories.map(cat => (
                        <option key={cat} value={cat}>{cat}</option>
                    ))}
                </select>}
                {!addCategory && (
                    <div className='container flex-col items-start justify-start py-4'>
                        <button onClick={handleAddNewcategoryInputField} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center">
                            Kategorie hinzufügen&nbsp;&#10010;
                        </button>
                    </div>
                )}
                {addCategory && (
                    <div className='container flex-col items-start justify-start py-4'>
                        <input type="text" id='categoryInput' value={category} onChange={handleNewCategoryWithAlreadySelected} placeholder="Neue Kategorie" required />
                        <p className='mt-2 text-sm text-gray-900'>Wenn Sie eine neue Kategorie eingegeben haben, wird diese automatisch beim abschließen übernommen.</p>
                        <button onClick={handleCloseNewcategoryInputField} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center">
                            Abbrechen
                        </button>
                    </div>
                )}
                <div className="flex items-center justify-center w-full">
                    <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-auto border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50" onDrop={handleDrop} onDragOver={handleDragOver} id='drop-zone'>
                        <div className="flex flex-col items-center justify-center pt-5 pb-6" id='drop-zone'>
                            <svg className="w-8 h-8 mb-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                            </svg>
                            <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Klicken zum hochladen</span> oder ziehen Sie die Datei hierher</p>
                            <ul id="file-list">
                                {files.map((file, index) => (
                                    <li className='text-sm flex gap-2 p-2' key={index}>
                                        📂&nbsp;{file.name}
                                        <button
                                            className="hover:bg-gray-300 p-0 m-0 border-gray-300 border-[1px] py-1 px-2 flex items-center rounded text-xs"
                                            onClick={(e) => removeFile(index, e)}
                                        >
                                            Entfernen
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-3 h-3 p-0 m-0">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        </button></li>

                                ))}
                            </ul>
                            {files.length >= 1 ? <button
                                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded"
                                onClick={(e) => clearFiles(e)}
                            >
                                Gesamte Auswahl entfernen
                            </button> : <></>}
                        </div>
                        <input id="dropzone-file" type="file" className="hidden" multiple onChange={handleFileChange} required />
                    </label>
                </div>
                <div className="container py-6">
                    <button type="submit" className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Abschließen und Speichern</button>
                </div>
            </form>
            <div className="gallery-container">
                {categories.map(category => (
                    <div key={category} className='mt-24'>
                        <h2 className="category-title">{category}</h2>
                        <div className="grid gap-2 grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
                            {images.filter(image => image.category === category).map((image) => (
                                <div key={image.id} className="image-container">
                                    <img src={`data:image/jpeg;base64,${image.data}`} alt={image.title} />
                                    <div className="delete-icon text-white py-2 px-4 rounded-lg bg-gray-600" onClick={() => handleDeleteClick(image.id)}>
                                        <div className='flex items-center justify-center gap-2'>
                                            <p>Löschen</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 p-0 m-0">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            {showModal && (
                <ConfirmModal
                    message="Möchten Sie das Bild endgültig löschen?"
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
            )}
        </div>
    );
}

export default Admin;
