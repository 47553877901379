import React, { useState, useEffect } from 'react';
import ImageFullscreenModal from '../../components/modals/ImageFullscreenModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useLocation } from 'react-router-dom';

interface ImageData {
    id: number;
    title: string;
    data: string;
    category: string;
}

function Gallery() {
    const [images, setImages] = useState<ImageData[]>([]);
    const [showImageFullscreenModal, setShowImageFullscreenModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const location = useLocation();
    const [selectedCategory, setSelectedCategory] = useState('Alle');
    const [width, setWidth] = useState(window.innerWidth);
    const [categories, setCategories] = useState<string[]>([]);
    const [, setAllCategoriesLoaded] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const abortController = new AbortController();
    const breakpoint = 768;

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const loadImagesForCategory = async (category: string) => {
        const url = `/api/image/images/category/${category}`;
        try {
            const response = await fetch(url, { signal: abortController.signal });
            const data = await response.json() as ImageData[];
            setImages(prevImages => {
                const existingIds = new Set(prevImages.map(img => img.id));
                const newImages = data.filter(img => !existingIds.has(img.id));
                return [...prevImages, ...newImages];
            });
        } catch (error) {
            console.error('Fehler beim Abrufen der Bilder:', error);
        }
    };

    const loadAllCategoriesSequentially = async (categories: string[]) => {
        for (const category of categories) {
            await loadImagesForCategory(category);
        }
        setAllCategoriesLoaded(true);
    };

    useEffect(() => {
        const startCategory = selectedCategory !== 'Alle' ? selectedCategory : (categories.length > 0 ? categories[0] : null);
    
        if (startCategory) {
            loadImagesForCategory(startCategory).then(() => {
                if (selectedCategory === 'Alle') {
                    const otherCategories = categories.filter(cat => cat !== startCategory);
                    loadAllCategoriesSequentially(otherCategories);
                }
            });
        }
        return () => {
            abortController.abort();
        };
    }, [selectedCategory, categories]);


    const handleImageFullscreenOpen = (imageId: number) => {
        const specificImage = images.find(img => img.id === imageId);
        if (specificImage) {
            setSelectedImage(`data:image/jpeg;base64,${specificImage.data}`);
            setShowImageFullscreenModal(true);
        }
    };

    const handleImageFullscreenClose = () => {
        setShowImageFullscreenModal(false);
        setSelectedImage(null);
    };

    const getImagesForColumn = (colIndex: number, images: ImageData[]) => {
        // Für mobile Geräte (wenn width <= breakpoint) werden die Bilder auf zwei Spalten verteilt
        if (width <= breakpoint) {
            return images.filter((_, index) => index % 2 === colIndex);
        }
    
        // Für Desktop-Geräte werden die Bilder über drei Spalten verteilt
        return images.filter((_, index) => index % 3 === colIndex);
    };
    

    const filteredImages = selectedCategory === 'Alle' 
    ? images 
    : images.filter(image => image.category === selectedCategory);

    useEffect(() => {
        fetch('/api/image/categories')
            .then(response => response.json())
            .then((categoriesData: string[]) => {
                const sortedCategories = categoriesData.sort();
                setCategories(sortedCategories);
                if (sortedCategories.length > 0) {
                    loadImagesForCategory(sortedCategories[0]); // Laden der ersten Kategorie
                }
                return sortedCategories.slice(1); // Restliche Kategorien
            })
            .then((otherCategories) => {
                loadAllCategoriesSequentially(otherCategories);
            })
            .catch(error => console.error('Fehler beim Abrufen der Kategorien:', error));
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const categoryFromUrl = params.get('category');
        if (categoryFromUrl && categories.includes(categoryFromUrl)) {
            setSelectedCategory(categoryFromUrl);
        }
    }, [location, categories]);

    const columns = [[], [], []] as string[][];

    return (
        <div className="isolate pt-[100px] max-w-[1280px] max-[1328px]:mx-[48px] max-sm:mx-[14px] mx-auto">
            <div className="grid sm:grid-cols-1 text-left gap-6 gap-y-12 mb-24">
                <h1 className='font-bold text-2xl pt-8'>Willkommen in unserer Galerie, lassen Sie sich von den zahlreichen Projekten inspirieren.</h1>
                <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                >
                    <option value="Alle">Alle</option>
                    {categories.sort((a, b) => a.localeCompare(b)).map(category => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
                {categories.sort((a, b) => a.localeCompare(b)).map(category => {
                    const imagesToDisplay = selectedCategory === 'Alle' ? images : filteredImages;
                    const imagesForCategory = imagesToDisplay.filter(img => img.category === category);
    
                    return (
                        (selectedCategory === 'Alle' || category === selectedCategory) && (
                            <div key={category} id={category} className='h-auto'>
                                <h2 className="category-title">{category}</h2>
                                <div className={`grid ${width > breakpoint ? 'grid-cols-3' : 'grid-cols-2'} gap-6`}>
                                    {[...Array(width > breakpoint ? 3 : 2)].map((_, colIndex) => (
                                        <div key={colIndex} className='grid grid-cols-1 gap-6 h-full'>
                                            {getImagesForColumn(colIndex, imagesForCategory).map((image, index) => (
                                                <article key={index} className="relative h-full" onClick={() => handleImageFullscreenOpen(image.id)}>
                                                    <LazyLoadImage
                                                        src={`data:image/jpeg;base64,${image.data}`}
                                                        alt={image.title}
                                                        effect="opacity"
                                                        height={'100%'}
                                                        width={'100%'}
                                                        className='h-full w-full object-cover'
                                                        onLoad={() => setIsLoaded(true)}
                                                    />
                                                </article>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    );
                })}
            </div>
            {showImageFullscreenModal && selectedImage && (
                <ImageFullscreenModal
                    handleImageFullscreenClose={handleImageFullscreenClose}
                    image={selectedImage}
                    alt="Vollbildansicht des ausgewählten Bildes"
                />
            )}
        </div>
    );
    
}

export default Gallery;

