import { useEffect } from "react";
import "./Impressum.css"
import { Link } from "react-router-dom";

function Impressum() {
    useEffect(() => {
        document.title = 'Metallbau-Fleischmann | Impressum';
    }, []);
    return (
        <div className='imp-container'>
            <div className="relative isolate pt-32 max-w-[1280px] w-full mx-0 text-left">
                <h2 className="w-full max-xl:mx-12 mb-0 text-3xl font-semibold text-gray-900 text-left pb-12">Impressum</h2>
                <div className="impressum-content-container max-w-[1280px] max-xl:mx-[48px]">
                    <div className="company-credentials-holder">
                        <h3>Günter Fleischmann GmbH</h3>
                        <p>Netzschkauer Str. 28b</p>
                        <p>08499, Reichenbach Ortsteil Mylau</p>
                    </div>
                    <div className="register-content-holder">
                        <h3>Kontakt</h3>
                        <p>Tel.:03765 31118</p>
                        <p>Fax:03765 34240</p>
                        <p>E-mail: info@metallbau-fleischmann.de</p>
                    </div>
                    <div className="register-content-holder">
                        <h3>Registereintrag</h3>
                        <p>HRB 10026 Amtsgericht Chemnitz</p>
                        <p>Ust-ID: DE164763191</p>
                    </div>
                    <div className="lead-holder">
                        <h3>Geschäftsführer</h3>
                        <p>Thomas Fleischmann</p>
                    </div>
                    <div className="powered-by-holder">
                        <h3>Webdesign & Programmierung</h3>
                        <Link to="https://nodiatech.de/" target="_SEJ" rel="noopener" className="powered-by-link">NodiaTech UG (haftungsbeschränkt)
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Impressum;