import React, { useEffect, useState } from 'react';
import './Navbar.css'
import { Link } from 'react-router-dom';
import logo from "../../assets/images/fleischmannlogo.webp"

interface NavbarProps {

}

const Navbar: React.FC<NavbarProps> = (props) => {
    const [isActive, setIsActive] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMenuClick = () => {
        setIsActive(!isActive);
    };
    return (
        <div className={`g-nav z-10 header ${isActive ? 'active' : ''}`} style={{
            backgroundColor: isScrolled ? '#fff' : 'transparent',
            transition: 'background-color 0.1s ease',
        }}>
            <div className="g-nav__content-wrapper">
                <div className="logo-wrapper">
                    <Link to='/' className='flex items-center gap-6'>
                        <img src={logo} width={80} height={40} alt="" />
                        <span className='text-3xl font-bold logo-span'>Metallbau Fleischmann</span>
                    </Link>
                </div>
                <div className='horizMenu'>
                    <ul className='horizNav-list'>
                        <li className=''>
                            <Link to='/' className='text-gray-900 text-xl font-medium nav-link'>Startseite</Link>
                        </li>
                        <li className=''>
                            <Link to='/ueber-uns' className='text-gray-900 text-xl font-medium nav-link'>Wir über uns</Link>
                        </li>
                        <li className=''>
                            <Link to='/kontakt' className='text-gray-900 text-xl font-medium nav-link'>Kontakt</Link>
                        </li>
                        <li className=''>
                            <Link type='button' to={'/galerie'} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-3.5 focus:outline-none flex gap-2 items-center justify-center">Jetzt&nbsp;Galerie&nbsp;entdecken&nbsp;<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                            </svg>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className={`menu-wrapper ${isActive ? 'active' : ''}`} onClick={handleMenuClick}>
                    <div className={`menu-container ${isActive ? 'active' : ''}`}>
                        <div className="line line-1">
                            <div className="line-inner bg-gray-900 line-inner-1"></div>
                        </div>
                        <div className="line line-2">
                            <div className="line-inner bg-gray-900 line-inner-2"></div>
                        </div>
                    </div>
                    <p>Menü</p>
                </div>
                <div className={`menu-items-container ${isActive ? 'active' : ''}`}>
                    <ul>
                        <li className={`mc-li-1 ${isActive ? 'active' : ''} hover:bg-[#1e40af]`}>
                            <Link to={'/'} onClick={handleMenuClick} className='text-gray-900 text-xl font-medium nav-link'>Startseite</Link>
                        </li>
                        <li className={`mc-li-2 ${isActive ? 'active' : ''} hover:bg-[#1e40af]`}>
                            <Link to={'/ueber-uns'} onClick={handleMenuClick} className='text-gray-900 text-xl font-medium nav-link'>Wir über uns</Link>
                        </li>
                        <li className={`mc-li-3 ${isActive ? 'active' : ''} hover:bg-[#1e40af]`}>
                            <Link to={'/kontakt'} onClick={handleMenuClick} className='text-gray-900 text-xl font-medium nav-link'>Kontakt</Link>
                        </li>
                        <li className={`mc-li-3 ${isActive ? 'active' : ''}`}>
                            <Link onClick={handleMenuClick} type='button' to={'/galerie'} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-3.5 focus:outline-none flex gap-2 items-center justify-center">Jetzt&nbsp;Galerie&nbsp;entdecken&nbsp;<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                            </svg>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Navbar;