import React, { useState } from 'react';
import TestImagePrev from "../../assets/images/comparison/Fassade-Vorher (2.2).JPG"
import TestImageAfter from "../../assets/images/comparison/Fassade-Nachher (2.2).JPG"
// Importiere weitere benötigte Komponenten oder Bibliotheken

const CompSlider = () => {
    // State und Logik hier (wenn benötigt)
    const [sliderPosition, setSliderPosition] = useState(50);
    const [isDragging, setIsDragging] = useState(false);

    const updatePosition = (clientX: number, rect: { left: number; width: number; }) => {
        const x = Math.max(0, Math.min(clientX - rect.left, rect.width));
        const percent = Math.max(0, Math.min((x / rect.width) * 100, 100));
        setSliderPosition(percent);
    };

    const handleMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isDragging) return;
        const rect = event.currentTarget.getBoundingClientRect();
        const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
        const percent = Math.max(0, Math.min((x / rect.width) * 100, 100))

        setSliderPosition(percent)
    }

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
        if (!isDragging) return;
        const rect = event.currentTarget.getBoundingClientRect();
        // Verwenden Sie das erste Touch-Event, da wir nur an einem Finger interessiert sind
        const touch = event.touches[0];
        updatePosition(touch.clientX, rect);
    };


    const handleMouseDown = () => {
        setIsDragging(true);
    }

    const handleMouseUp = () => {
        setIsDragging(false);
    }

    return (
        <div className='w-full relative' onMouseUp={handleMouseUp} onTouchEnd={handleMouseUp}>
            <div
                className='max-lg:mt-6  relative w-full select-none max-w-[1280px] aspect-[70/45] m-auto rounded-2xl  overflow-hidden'
                onMouseMove={handleMove}
                onTouchMove={handleTouchMove}
                onMouseDown={handleMouseDown}
                onTouchStart={handleMouseDown}
            >
                <img src={TestImageAfter} alt="" />
                <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-gray-500 absolute bottom-6 right-6">Nachher</span>
                <div
                    className='absolute select-none top-0 left-0 right-0 w-full max-w-[1280px] aspect-[70/45] m-auto overflow-hidden'
                    style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
                >
                    <img src={TestImagePrev} alt="" />
                    <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-gray-500 absolute bottom-6 left-6">Vorher</span>

                </div>
                <div
                    className='absolute top-0 bottom-0 w-1 bg-white cursor-ew-resize'
                    style={{ left: `calc(${sliderPosition}% - 1px)` }}
                >
                    <div className='bg-white absolute rounded-full h-5 w-5 -left-2 top-[calc(50%-5px)]' />
                </div>
            </div>
        </div>
    );
};

export default CompSlider;


