import './ImageFullscreenModal.css';

interface ImageFullscreenModalProps {
    image: string;
    alt: string;
    handleImageFullscreenClose: () => void;
}

function ImageFullscreenModal({ image, alt, handleImageFullscreenClose }: ImageFullscreenModalProps) {
    return (
        <div className="modal-fs pt-24 pb-[11px] cursor-default">
            <div className='modal-content-fs'>
                <img src={image} alt={alt} className='object-contain w-[640px] h-auto' />
                <div onClick={handleImageFullscreenClose} className='cursor-pointer absolute top-2 right-2 p-2 bg-gray-600 text-white rounded-lg'>Schließen</div>
            </div>
        </div>
    );
}

export default ImageFullscreenModal